import { useChangeRoute } from "@/hooks/useChangeRoute";
import clsx from "clsx";
import { createContext } from "react";

interface ILoadingContext {
  loading: boolean;
  stopLoading: (url: string) => void;
  onLoading: (url: string) => void;
}

export const LoadingContext = createContext<ILoadingContext>({
  loading: false,
  stopLoading: () => {},
  onLoading: () => {},
});

export function LoadingContextProvider(props: any): JSX.Element {
  const { loading, onLoading, stopLoading } = useChangeRoute();

  return (
    <LoadingContext.Provider value={{ loading, onLoading, stopLoading }}>
      {
        <div
          className={clsx(
            "loading-line duration-300",
            loading ? "opacity-100 z-[9999]" : "opacity-0 -z-[9999]"
          )}>
          <div className="bar" />
        </div>
      }
      {props.children}
    </LoadingContext.Provider>
  );
}
