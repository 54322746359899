import omit from "lodash.omit";
import { AppAPIConfig } from "../index";
import { CustomNodeJsGlobal } from "../type";
import { FetcherError } from "./error";
// Get an instance to vercel fetch

// Get token util
let headerRequest = {};

export const setHeaderRequest = (data) => {
  headerRequest = data;
};

export async function fetchData<T>(opts: {
  token: string;
  path: string;
  method: string;
  config: AppAPIConfig;
  fetchOptions?: Record<string, any>;
  body?: BodyInit;
}): Promise<T | null> {
  // Destructure opts
  const { body, fetchOptions, config, method = "GET", path } = opts;
  // Do the request with the correct headers

  const headerFetch = {
    ...headerRequest,
    ...fetchOptions,
    ...fetchOptions?.headers,
    accept: "application/json, text/plain, */*",
    "Accept-Language": "vi",
  };

  // if (token) {
  //   headerFetch.authorization = `Bearer ${token}`;
  // }

  if (!fetchOptions?.formData) {
    headerFetch["Content-Type"] =
      fetchOptions?.contentType ?? "application/json";
  }

  const url = `${config.apiUrl}/${path}`;

  try {
    const dataResponse = await fetch(url, {
      ...fetchOptions,
      method,
      headers: omit(headerFetch, "token"),
      body: body
        ? fetchOptions?.contentType || fetchOptions?.formData
          ? body
          : JSON.stringify(body)
        : undefined,
    });

    // If something failed getting the data response
    if (!dataResponse.ok) {
      // Get the body of it
      // const error = null
      const message = await dataResponse.text();
      // And return an error
      throw new FetcherError({
        errors: [{ message: message }],
        status: dataResponse.status,
      });
    }

    try {
      // Return data response as json
      return (await dataResponse.json()) as Promise<T>;
    } catch (error) {
      console.error("Fail to cast response to json.......", url, error);
      // If response is empty return it as text
      // return null as unknown as Promise<T>
      throw error;
    }
  } catch (error) {
    console.log(" ------ FAIL TO CALL API ------", url);
    console.log(error);

    return null;
    //throw error
  }
}

export const createFetcher: <DataType>(
  getConfig: () => AppAPIConfig
) => (
  method: string,
  path: string,
  body?: any,
  fetchOptions?: Record<string, any>
) => Promise<DataType> =
  (getConfig) =>
  async <T>(
    method: string,
    path: string,
    body?: any,
    fetchOptions?: Record<string, any>,
    formData?: boolean
  ) => {
    const customGlobal = global as unknown as CustomNodeJsGlobal;

    // Get provider config
    const config = getConfig();

    // If a token was passed, set it on global
    if (fetchOptions?.token) {
      customGlobal.token = fetchOptions.token;
    }

    // Return the data and specify the expected type
    const data = await fetchData<T>({
      token: customGlobal.token as string,
      fetchOptions,
      config,
      method,
      path,
      body,
    });
    return data;
    // return {
    // 	...data,
    // 	meta: { token: (customGlobal.token as string) || null },
    // }
  };
