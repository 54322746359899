import { useState, useEffect } from 'react'
import { useRouter } from 'next/router'

export const useChangeRoute = () => {
	const router = useRouter()
	const [loading, setLoading] = useState(false)

	const startLoading = (url: string) => {
		if (url !== router.asPath) {
			setLoading(true)
		}
	}

	const stopLoading = (url: string) => {
		setLoading(false)
	}

	useEffect(() => {
		router.events.on('routeChangeStart', startLoading)
		router.events.on('routeChangeComplete', stopLoading)
		router.events.on('routeChangeError', stopLoading)

		return () => {
			router.events.off('routeChangeStart', startLoading)
			router.events.off('routeChangeComplete', stopLoading)
			router.events.off('routeChangeError', stopLoading)
		}
	})

	return {
		router,
		loading,
		onLoading: startLoading,
		stopLoading,
	}
}
