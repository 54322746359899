import { setHeaderRequest } from "@/api/utils/fetch-api";
import { USER_TYPE } from "@/constants/common-constant";
import { AUTH_DATA } from "@/constants/cookies";
import FingerprintId from "@/utils/browserFingerprint";
import { deleteCookie, getCookie, setCookie } from "cookies-next";
import { UserFromMeeyId } from "Models";
import { useCallback, useEffect, useMemo, useState } from "react";

export const useAuth = () => {
  const [checked, setChecked] = useState<boolean>(false);
  const [token, setToken] = useState<string>();
  const [user, setUser] = useState<UserFromMeeyId>();

  const saveUserToRedux = useCallback(async (auth?: any) => {
    if (auth) {
      // sync favorite

      //set token user
      setToken(auth.access_token);
      //
      //set user
      setUser(auth.user);
      //set cookie
      setCookie(AUTH_DATA, JSON.stringify(auth));
      // set user sdk
    } else {
      setToken(null);
      setUser(null);
      deleteCookie(AUTH_DATA);
    }
    setChecked(true);
  }, []);

  const syncAuth = useCallback(() => {
    // @ts-ignore
    const userMeeyId = window.MeeyId && window.MeeyId?.getUser();
    const storedAuth: any = getCookie(AUTH_DATA);
    const auth = userMeeyId
      ? userMeeyId
      : storedAuth
      ? JSON.parse(storedAuth)
      : null;

    if (auth?.access_token) {
      saveUserToRedux(auth);
    } else {
      // @ts-ignore
      const userMeeyId = window.MeeyId && window.MeeyId?.getUser();
      if (userMeeyId?.access_token) {
        saveUserToRedux(userMeeyId);
      } else {
        saveUserToRedux(null);
      }
    }
    setChecked(true);
  }, [saveUserToRedux]);

  const login = useCallback(() => {
    // @ts-ignore
    window.MeeyId && window.MeeyId.showLogin();
    const storedAuth = getCookie(AUTH_DATA);
    if (storedAuth) {
      syncAuth();
    }
  }, [syncAuth]);

  const logout = useCallback(() => {
    // @ts-ignore
    window.MeeyId && window.MeeyId.signOut();
    setCookie(
      "headers",
      JSON.stringify({
        usertype: USER_TYPE.ANONYMUS,
        fingerprint: FingerprintId(),
      })
    );
    const storedAuth = getCookie(AUTH_DATA);
    if (!storedAuth) {
      syncAuth();
    }
  }, [syncAuth]);

  const userDataHeader = useMemo(() => {
    let headerUser = null;
    if (user)
      headerUser = {
        usertype: USER_TYPE.LOGIN,
        Authorization: `Bearer ${token}`,
      };
    else if (checked)
      headerUser = {
        usertype: USER_TYPE.ANONYMUS,
        fingerprint: FingerprintId(),
      };
    else return null;
    setHeaderRequest(headerUser);
    return headerUser;
  }, [checked, token, user]);

  useEffect(() => {
    syncAuth();
  }, [syncAuth]);

  const isBroker = false;
  return {
    token,
    user,
    checked,
    login,
    logout,
    syncAuth,
    isBroker,
    userDataHeader,
  };
};
