import { SDK_URL, STATIC_DOMAIN } from "@/constants/environment";
import { DeviceContextProvider } from "@/context/device.context";
import { LoadingContextProvider } from "@/context/loading.context";
import { useAuth } from "@/hooks/useAuth";
import ButtonToTop from "@/modules/common/ButtonToTop";
import Footer from "@/modules/common/Footer";
import { setCookies } from "cookies-next";
import App, { AppContext, AppProps } from "next/app";
import NextHead from "next/head";
import Script from "next/script";
import { useCallback, useEffect } from "react";
import "../styles/globals.scss";

type TProps = AppProps & {
  isMobile: boolean;
};

const CustomApp = ({ Component, pageProps, isMobile }: TProps) => {
  const isAmp = true;
  const { userDataHeader, checked } = useAuth();
  // @ts-ignore
  const disableDragDropFile = useCallback((e: Event) => {
    e.preventDefault();
  }, []);

  useEffect(() => {
    window.addEventListener("dragover", disableDragDropFile, false);
    window.addEventListener("drop", disableDragDropFile, false);
  }, [disableDragDropFile]);
  useEffect(() => {
    setCookies("headers", JSON.stringify(userDataHeader));
  }, [checked, userDataHeader]);
  return (
    <>
      <NextHead>
        {!isAmp ? (
          <>
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0, minimum-scale=1"
            />
            <meta
              httpEquiv="Content-Security-Policy"
              content="upgrade-insecure-requests"
            />
            <meta name="apple-mobile-web-app-capable" content="yes" />
          </>
        ) : null}
      </NextHead>

      <LoadingContextProvider>
        {/*   <DeviceContextProvider isMobile={isMobile}>
          <ScrollContextProvider scrollTop={0}> */}
        <DeviceContextProvider isMobile={isMobile}>
          <Component {...pageProps} />
          <ButtonToTop />
          <Footer />
        </DeviceContextProvider>
        {/* </ScrollContextProvider>
        </DeviceContextProvider> */}
      </LoadingContextProvider>
    </>
  );
};

CustomApp.getInitialProps = async (context: AppContext) => {
  const ctx = await App.getInitialProps(context);

  let userAgent;
  if (context.ctx.req) {
    // if you are on the server and you get a 'req' property from your context
    userAgent = context.ctx.req.headers["user-agent"]; // get the user-agent from the headers
  } else {
    userAgent = navigator.userAgent; // if you are on the client you can access the navigator from the window object
  }

  const isMobile = Boolean(
    userAgent.match(
      /Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i
    )
  );
  return { ...ctx, isMobile };
};
export default CustomApp;
