import Link from "next/link";
import React from "react";

const Footer = () => {
  return (
    <div className=" w-full border-t border-solid border-grey-200 bg-grey-100 pt-3 pb-4 flex flex-col items-center">
      <span className="text-fs-14 text-grey-700 font-medium text-center">
        Meey Review - Diễn đàn đánh giá đa chiều bất động sản
      </span>
      <div className="flex items-end gap-3 mt-2">
        <Link
          href={"https://help.meeyreview.com/"}
          target="_blank"
          className="text-blue-500 text-center text-fs-12 border-r border-solid border-grey-300 pr-3"
        >
          Về Meey Review
        </Link>
        <Link
          href={"https://help.meeyreview.com/dieu-khoan-dieu-kien"}
          target="_blank"
          className="text-blue-500 text-center text-fs-12 border-r border-solid border-grey-300 pr-3"
        >
          Điều khoản điều kiện
        </Link>
        <Link
          href={"https://help.meeyreview.com/huong-dan-s-dung"}
          className="text-blue-500 text-center text-fs-12"
          target="_blank"
        >
          Hướng dẫn sử dụng
        </Link>
      </div>
    </div>
  );
};

export default Footer;
