import clsx from "clsx";
import React, { useState } from "react";

const ButtonToTop = () => {
  const [visible, setVisible] = useState(false);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true);
    } else if (scrolled <= 300) {
      setVisible(false);
    }
  };

  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  if (typeof window !== "undefined") {
    window.addEventListener("scroll", toggleVisible);
  }
  const isBrowser = () => typeof window !== "undefined";
  return isBrowser ? (
    <div
      className={clsx(
        "h-10 w-10 rounded-full bg-white shadow-light-1 flex items-center justify-center fixed z-10 bottom-24 right-4 md:cursor-pointer",
        visible ? "block" : "hidden"
      )}
      onClick={handleScrollToTop}
    >
      <i className="mrv mrv-arrow_upward text-fs-28 md:text-fs-24 text-grey-400"></i>
    </div>
  ) : null;
};

export default ButtonToTop;
