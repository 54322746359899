export const SELECT_PAGE_SIZE = 5;
export const TIMEOUT_LOAD_MORE = 1000;
export const TIMEOUT_LOAD_SEARCH = 1000;
export const TIMEOUT_HIDE_CONTROL = 3000;
export const TIMEOUT_PREVIEW = 10000;
export const ITEM_LOAD_MORE: number = 10;
export const TIME_SECOND_SEEK_DEFAULT: number = 10;
export const DEFAULT_LIMIT_SEARCH_VIDEO = 20;
export const DEFAULT_LIMIT_SEARCH_PROJECT = 10;
export const DEFAULT_IMAGE_CARD =
  "/meeyproject/images/2022/10/07/mat-bang-vinhomes-van-giang-tieu-khu-ocean-park-3.1665113290186.jpg";
export enum UserTypeEnum {
  USER_TYPE_LOGIN = 1,
  USER_TYPE_GUEST = 2,
}
export const urlRegex =
  /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
export const SLLIDER_BREAKPOINT = {
  1921: {
    slidesPerView: 6,
    slidesPerGroup: 6,
  },
  1441: {
    slidesPerView: 5,
    slidesPerGroup: 5,
  },
  1367: {
    slidesPerView: 4,
    slidesPerGroup: 4,
  },
  1201: {
    slidesPerView: 3,
    slidesPerGroup: 3,
  },
  769: {
    slidesPerView: 2,
    slidesPerGroup: 2,
  },
  481: {
    slidesPerView: 1,
    slidesPerGroup: 1,
  },
};
export const HTTP_STATUS_CODE = {
  CONTINUE: 100,
  SWITCHING_PROTOCOLS: 101,
  PROCESSING: 102,
  EARLY_HINTS: 103,
  OK: 200,
  CREATED: 201,
  ACCEPTED: 202,
  NON_AUTHORITATIVE_INFORMATION: 203,
  NO_CONTENT: 204,
  RESET_CONTENT: 205,
  PARTIAL_CONTENT: 206,
  MULTI_STATUS: 207,
  ALREADY_REPORTED: 208,
  IM_USED: 226,
  MULTIPLE_CHOICES: 300,
  MOVED_PERMANENTLY: 301,
  FOUND: 302,
  SEE_OTHER: 303,
  NOT_MODIFIED: 304,
  USE_PROXY: 305,
  TEMPORARY_REDIRECT: 307,
  PERMANENT_REDIRECT: 308,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  PAYMENT_REQUIRED: 402,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  METHOD_NOT_ALLOWED: 405,
  NOT_ACCEPTABLE: 406,
  PROXY_AUTHENTICATION_REQUIRED: 407,
  REQUEST_TIMEOUT: 408,
  CONFLICT: 409,
  GONE: 410,
  LENGTH_REQUIRED: 411,
  PRECONDITION_FAILED: 412,
  PAYLOAD_TOO_LARGE: 413,
  URI_TOO_LONG: 414,
  UNSUPPORTED_MEDIA_TYPE: 415,
  RANGE_NOT_SATISFIABLE: 416,
  EXPECTATION_FAILED: 417,
  IM_A_TEAPOT: 418,
  MISDIRECTED_REQUEST: 421,
  UNPROCESSABLE_ENTITY: 422,
  LOCKED: 423,
  FAILED_DEPENDENCY: 424,
  TOO_EARLY: 425,
  UPGRADE_REQUIRED: 426,
  PRECONDITION_REQUIRED: 428,
  TOO_MANY_REQUESTS: 429,
  REQUEST_HEADER_FIELDS_TOO_LARGE: 431,
  UNAVAILABLE_FOR_LEGAL_REASONS: 451,
  INTERNAL_SERVER_ERROR: 500,
  NOT_IMPLEMENTED: 501,
  BAD_GATEWAY: 502,
  SERVICE_UNAVAILABLE: 503,
  GATEWAY_TIMEOUT: 504,
  HTTP_VERSION_NOT_SUPPORTED: 505,
  VARIANT_ALSO_NEGOTIATES: 506,
  INSUFFICIENT_STORAGE: 507,
  LOOP_DETECTED: 508,
  BANDWIDTH_LIMIT_EXCEEDED: 509,
  NOT_EXTENDED: 510,
  NETWORK_AUTHENTICATION_REQUIRED: 511,
};
export const DATE_FORMAT = {
  DAY_ONLY_SLASH: "DD/MM/YYYY",
  DAY_ONLY_BACKEND: "YYYY-MM-DD",
  DAY_DOT_VN: "DD.MM.YYYY",
  DATE_TIME_NO_SECOND: "HH:mm, DD.MM.YYYY",
  DATE_TIME_NO_SECOND_SLASH: "HH:mm DD/MM/YYYY",
};

export const TIME_FORMAT = {
  FULL: "HH:mm:ss",
  HOUR_MINUTE: "HH:mm",
};

export const MILISECONDS_1_DAY = 84600000;

export const SEO_META_DESCRIPTION_CONTENT_LENGTH = 200;

export const LIST_COLOR_AVATAR = [
  {
    backgroundColor: "#F1F8FF",
    color: "#366FEB",
  },
  {
    backgroundColor: "#FFF7E1",
    color: "#F3960A",
  },
  {
    backgroundColor: "#EFFEE9",
    color: "#08A822",
  },
  {
    backgroundColor: "#F4F2FF",
    color: "#7163CB",
  },
  {
    backgroundColor: "#FFF0ED",
    color: "#E35B47",
  },
  {
    backgroundColor: "#ECFFFC",
    color: "#0AB1BC",
  },
  {
    backgroundColor: "#FDF3E0",
    color: "#865931",
  },
  {
    backgroundColor: "#FFECFB",
    color: "#830897",
  },
  {
    backgroundColor: "#FEFFD9",
    color: "#749C02",
  },
  {
    backgroundColor: "#ECF8FF",
    color: "#005B90",
  },
];

export const USER_TYPE = {
  LOGIN: 1,
  ANONYMUS: 2,
};

export const FOLLOW_PROJECT_STATUS = {
  UN_FOLLOW: 0,
  FOLLOW: 1,
};

export const MAX_REVIEW_FILE_UPLOAD = 10;

export const MAX_LENGTH_SEARCH_INPUT = 100;

export enum FluctuationScoreEnum {
  EQUAL = 0,
  UP = 1,
  DOWN = 2,
}
