export const AUTH_DATA = 'auth'

export const SAVE_DRAFT = 'draft'

export const REDIRECT_FROM_FILTER = 'redirect-from-filter'

// LOCAL STORAGE
export const LOCAL_FAVORITE_ARTICLE = 'favorite_articles'

export const LOCAL_WATCH_ARTICLE = 'watch_articles'

export const LOCAL_SEARCH_HISTORY = 'MH_search_histories'
